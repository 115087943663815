import { addDays, subDays } from "date-fns";
import React from "react";
import BiofixDateInput from "../../components/models/biofixDateInput";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./sooty-blotch-fly-speck.json";
import Acknowledgments from "./_acknowledgements";
import ManagementGuide from "./_managementGuide";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function SootyBlotchAndFlySpeck() {
  const h1Text = modelData.title;
  const { id, title, seasonStart, seasonEnd, elements } = modelData;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const [userBiofix1, setUserBiofix1] = React.useState(null);
  const [userBiofix2, setUserBiofix2] = React.useState(null);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(
      modelData,
      data,
      dateOfInterest,
      userBiofix1,
      userBiofix2
    );
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const isVisible = station && mData;

  React.useEffect(() => {
    if (user && Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === id);
      if (model) {
        if (
          Object.keys(model.biofix1).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix1[dateOfInterest.year]).includes(station.id)
        ) {
          setUserBiofix1(model.biofix1[dateOfInterest.year][station.id]);
        } else {
          setUserBiofix1(null);
        }

        if (
          Object.keys(model.biofix2).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix2[dateOfInterest.year]).includes(station.id)
        ) {
          setUserBiofix2(model.biofix2[dateOfInterest.year][station.id]);
        } else {
          setUserBiofix2(null);
        }
      }
    }
  }, [station, id, user, dateOfInterest]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      <div className={`mb-16 sm:mb-18 md:mb-20`}>
        {isModelInSeason && isVisible && (
          <>
            <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
              Petal fall
            </h2>
            <div className="px-6 py-6 bg-white rounded-lg shadow-lg">
              {mData.selectedDate.degreeDaysToPetalFall >= 0 && (
                <p className="p-2 mx-auto mb-2 text-gray-700 rounded-md bg-gray-50">
                  You are approximately{" "}
                  <span className="font-extrabold">
                    {mData.selectedDate.degreeDaysToPetalFall}
                  </span>{" "}
                  from petal fall - the point at which apples and pears become
                  susceptible to infection. Sooty Blotch and Fly Speck
                  predictions begin at petal fall.
                </p>
              )}
              <div className="flex items-center pt-6 pb-2 mx-auto w-72">
                {mData.selectedDate.displayBiofix1Input && (
                  <BiofixDateInput
                    modelId={id}
                    id={"biofix1"}
                    dbKey="biofix1"
                    minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                    maxDate={
                      mData.biofix2
                        ? subDays(mData.biofix2.date, 1)
                        : dateOfInterest.date
                    }
                    biofix={mData.biofix1}
                    userBiofix={userBiofix1}
                    setUserBiofix={setUserBiofix1}
                    label={elements.userInputs.firstInput.label}
                    modelName={title}
                    tooltip={`Reset petal fall to default value`}
                    datesWithNoData={[]}
                    station={station}
                  ></BiofixDateInput>
                )}
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col items-center py-2">
                  {mData.selectedDate.displayBiofix1Input && (
                    <p className="max-w-3xl p-2 mx-auto mb-6 text-gray-700 rounded-md bg-gray-50">
                      {mData.userInputMsg}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center pt-6 pb-2 mx-auto w-72">
                <BiofixDateInput
                  modelId={id}
                  id={"biofix2"}
                  dbKey="biofix2"
                  minDate={mData.biofix1 && addDays(mData.biofix1.date, 1)}
                  maxDate={dateOfInterest.date}
                  biofix={mData.biofix2}
                  userBiofix={userBiofix2}
                  setUserBiofix={setUserBiofix2}
                  label={elements.userInputs.secondInput.label}
                  modelName={title}
                  tooltip={`Reset biofix to default value`}
                  datesWithNoData={[]}
                  station={station}
                ></BiofixDateInput>
              </div>
              <p className="max-w-3xl p-2 mx-auto mb-6 text-sm text-gray-700 rounded-md">
                If petal fall has passed, enter the date of your most recent
                fungicide application. If no fungicide applications have been
                made, do not enter a date.
              </p>

              <p className="max-w-3xl p-2 mx-auto mt-10 mb-4 text-sm text-gray-700 rounded-md bg-gray-50">
                In the Risk Summary table, note the accumulated leaf wetness
                hours since petal fall (Leaf Wetness Hours) and the Risk Level.
                Leaf wetness hours, rain events, and the last fungicide
                application date are taken into consideration in assessing risk
                level. To estimate risk in the near future, look at the
                probability of rain.
              </p>
              <h2 className="pt-6 my-4 text-lg text-center">
                Consult the Risk Level IPM Guidelines below the Risk Summary
                table.
              </h2>
            </div>
          </>
        )}
      </div>

      {/* Infection Event Log */}
      {isModelInSeason && showResultsTable && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            modelData={modelData}
            tableData={mData.tableData}
            csvData={mData.csvData}
            userBiofix2={userBiofix2}
          ></ResultsTable>
        </div>
      )}

      {/* Management Guide */}
      {isModelInSeason && showManagementGuide && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ManagementGuide></ManagementGuide>
        </div>
      )}
    </ModelLayout>
  );
}
