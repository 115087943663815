import { format, getDayOfYear } from 'date-fns'
import { baskervilleEmin, calculateGdd } from '../../utils/helpers'
import { lookupTable } from './_lookupTable'

function riskLevel(a, b, c, obj) {
  // a = Days since petal fall
  // b = Rainfall since petal fall
  // c = Hours of leaf wetness since petal fall

  const r = typeof b === 'undefined' ? 0 : b
  // console.log({ date, a, b: r, c, obj })
  const { a_min, a_max, b_min, b_max, c_min, c_max } = obj
  const daysSincePetalFall = a >= a_min && a <= a_max
  const rainfallSincePetalFall = r >= b_min && r <= b_max
  const hrsLwetSincePetalFall = c >= c_min && c <= c_max

  if (daysSincePetalFall && rainfallSincePetalFall && hrsLwetSincePetalFall) {
    return obj
  }
}

export default function sootyBlotchFlySpeckLogic(
  modelData,
  data,
  dateOfInterest,
  userBiofix1 = null,
  userBiofix2 = null,
) {
  const { elements, base } = modelData
  const { userInputs } = elements

  const dailyDataWithGdd = calculateGdd(
    data.dailyData,
    base,
    0,
    baskervilleEmin,
  ).map((d) => {
    return { ...d, dateDisplay: format(d.date, 'yyyy-MM-dd') }
  })

  // User Biofix1 -------------------------------------------------------
  let userBiofix1Index = 0
  if (userBiofix1 !== null) {
    userBiofix1Index = dailyDataWithGdd.findIndex(
      (day) => day.dateDisplay === userBiofix1,
    )
  }
  // console.log(userBiofix1Index)

  // User Biofix2 -------------------------------------------------------
  let userBiofix2Index = 0
  if (userBiofix2 !== null) {
    userBiofix2Index = dailyDataWithGdd.findIndex(
      (day) => day.dateDisplay === userBiofix2,
    )
  }
  // console.log(userBiofix2Index)

  // Recalculating degree-days when biofix1 ----------------------------------
  let gdd1 = 0
  let daysSincePetalFallAccumulation = -1
  let daysSinceFungicideAccumulation = -1
  let accumulatedLeafWetnessHoursAboveOneMinute = 0
  let rainSinceLastFungisideApp = 0
  let cumulativeRainFall = 0

  const mData = dailyDataWithGdd.map((day, i) => {
    const dailyRainAmount =
      typeof day.prcpCumulative === 'undefined' ? 0 : day.prcpCumulative
    cumulativeRainFall += dailyRainAmount

    let pop10pm = null
    let pop10am = null
    let isPop12 = false
    if (day.pop12) {
      isPop12 = day.pop12.filter((d) => typeof d === 'number').length
    }
    if (i > 0 && isPop12 > 0) {
      pop10pm = dailyDataWithGdd[i - 1].pop12[21]
      pop10am = day.pop12[9]
    }

    if (
      day.dayOfYear === getDayOfYear(new Date()) &&
      dateOfInterest.isCurrentYear
    ) {
      pop10pm = null
      pop10am = day.pop12.slice(-1)[0]
    }

    let p = {
      date: day.date,
      dateDisplay: day.dateDisplay,
      dayOfYear: day.dayOfYear,
      ms: day.ms,
      isForecast: day.isForecast,
      lwet: day.lwet,
      dd: day.dd,
      gdd: day.gdd,
      displayBiofix1Input:
        day.gdd >= userInputs.firstInput.displayElement ? true : false,
      biofix1Gdd: 'N/A',
      degreeDaysToPetalFall: userInputs.firstInput.displayElement - day.gdd,
      daysSincePetalFall: 0,
      daysSinceFungicide: 0,
      daysSinceFungicideAccumulation: 0,
      accumulatedLeafWetnessHoursAboveOneMinute: 0,
      rainSinceLastFungisideApp: 0,
      riskLevel: 'N/A',
      dailyRainAmount,
      cumulativeRainFall: Number(cumulativeRainFall.toFixed(3)),
      pop10pm,
      pop10am,
    }
    const leafWetnessHoursAboveOneMinute = day.lwet.filter((d) => d >= 1).length

    // Recalculate if user has selected a biofix1 date
    if (userBiofix1 !== null) {
      if (i >= userBiofix1Index) {
        gdd1 += day.dd !== 'N/A' ? day.dd : 0
        p.biofix1Gdd = Math.round(gdd1)
        daysSincePetalFallAccumulation++
        p.daysSincePetalFall = daysSincePetalFallAccumulation
        accumulatedLeafWetnessHoursAboveOneMinute +=
          leafWetnessHoursAboveOneMinute
        p.accumulatedLeafWetnessHoursAboveOneMinute =
          accumulatedLeafWetnessHoursAboveOneMinute
      }
    } else {
      if (day.gdd >= userInputs.firstInput.displayElement) {
        gdd1 += day.dd !== 'N/A' ? day.dd : 0
        p.biofix1Gdd = Math.round(gdd1)
        daysSincePetalFallAccumulation++
        p.daysSincePetalFall = daysSincePetalFallAccumulation
        accumulatedLeafWetnessHoursAboveOneMinute +=
          leafWetnessHoursAboveOneMinute
        p.accumulatedLeafWetnessHoursAboveOneMinute =
          accumulatedLeafWetnessHoursAboveOneMinute
      }
    }

    if (userBiofix2 !== null) {
      if (i >= userBiofix2Index) {
        daysSinceFungicideAccumulation++
        p.daysSinceFungicide = daysSinceFungicideAccumulation
        rainSinceLastFungisideApp += dailyRainAmount
        p.rainSinceLastFungisideApp = Number(
          rainSinceLastFungisideApp.toFixed(3),
        )
      }
    }

    const riskLevelObj = lookupTable.find((obj) =>
      riskLevel(
        userBiofix2
          ? daysSinceFungicideAccumulation
          : daysSincePetalFallAccumulation,
        userBiofix2 ? rainSinceLastFungisideApp : 0,
        p.accumulatedLeafWetnessHoursAboveOneMinute,
        obj,
      ),
    )
    if (riskLevelObj) {
      p.riskLevel = riskLevelObj.risk
    }
    return p
  })
  // console.log(mData)
  ////////////////////////////////////////////////////////////////////////////////////

  let selectedDate = mData.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear,
  )
  if (!selectedDate) {
    selectedDate = mData.find((d) => d.isForecast)
  }

  // Biofix Dates ----------------------------------------------------------
  let biofix1 = null
  const b1 = mData.find((day) => day.biofix1Gdd !== 'N/A')
  if (typeof b1 !== 'undefined') biofix1 = b1

  if (biofix1) {
    if (selectedDate.dayOfYear < biofix1.dayOfYear) biofix1 = null
  }

  let biofix2 = null
  const b2 = mData.find((day) => day.dateDisplay === userBiofix2)
  if (typeof b2 !== 'undefined') biofix2 = b2

  if (biofix2) {
    if (selectedDate.dayOfYear < biofix2.dayOfYear) biofix2 = null
  }
  // console.log(biofix2)
  ////////////////////////////////////////////////////////////////////////////////////

  // determine biofix message Below user inputs ------------------------------------
  let userInputMsg = ''
  if (biofix1 === null) {
    userInputMsg = userInputs.firstInput.message.beforeBiofix
  } else {
    userInputMsg = userInputs.firstInput.message.afterBiofix
  }
  ////////////////////////////////////////////////////////////////////////////////////

  // Data to display in Results Table --------------------------------------------------
  let tableData = [...mData]
  if (mData.length >= 8) {
    tableData = mData.slice(
      selectedDate.dayOfYear - 3,
      selectedDate.dayOfYear + 5,
    )
  }
  // console.log(tableData)
  // //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  let csvData = []
  csvData = mData.map((day) => {
    let p = {
      date: day.dateDisplay,
      daysSincePetalFall: day.daysSincePetalFall,
      accumulatedLeafWetnessHours:
        day.accumulatedLeafWetnessHoursAboveOneMinute,
      dailyRainAmount: day.dailyRainAmount,
    }

    if (biofix2) {
      p.daysSinceFungicideApplication = day.daysSinceFungicide
      p.rainSinceLastFungisideApplication = day.rainSinceLastFungisideApp
    }

    return p
  })

  let currentDate = mData.find((d) => d.isForecast)
  let dashboardSummary = {
    currentDate: currentDate ? { ...currentDate } : null,
  }

  // console.log(csvData)
  //////////////////////////////////////////////////////////////////////////////////////
  return {
    data: mData,
    biofix1,
    biofix2,
    selectedDate,
    userInputMsg,
    tableData,
    csvData,
    dashboardSummary,
  }
}
