import React from "react"

const CustomInput = React.forwardRef(
  ({ value, onClick, placeholderText, isDisabled }, ref) => {
    return (
      <span className="flex items-center justify-center">
        <button
          ref={ref}
          disabled={isDisabled}
          type="button"
          aria-label="biofix"
          onClick={onClick}
          className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary-800 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-700 disabled:opacity-50"
        >
          <svg
            className="w-5 h-5 mr-3 -ml-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>

          {value ? <span>{value}</span> : <span>{placeholderText}</span>}
        </button>
      </span>
    )
  }
)

export default CustomInput
