import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      This NEWA disease forecast tool was authored by Dr. Kerik Cox, Plant Pathology and Plant-Microbe Biology, at Cornell University, with input from Dr. Dan Cooley, University of Massachussets. Please contact Dr. Cox <a href="mailto:kdc33@cornell.edu">kdc33@cornell.edu</a> with any questions regarding the scientific content and recommendations delivered in tool outputs.
      </p>
    </>
  )
}
