import GlobalStateContext from '../../context/globalStateContext'
import { useAuth0 } from '../../context/react-auth0-spa'
import { setDate } from '../../utils/helpers'
import CustomInput from './customInput'
import { getDayOfYear } from 'date-fns'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useSnackbar } from 'react-simple-snackbar'
import ReactTooltip from 'react-tooltip'

export default function BiofixDateInput({
  modelId,
  id,
  dbKey,
  minDate,
  maxDate,
  biofix,
  userBiofix,
  setUserBiofix,
  setUserBiofix2 = () => {},
  label,
  modelName,
  tooltip,
  datesWithNoData,
  isDisabled,
  station,
}) {
  // console.log(`${id}---------------------------------------------------------`)
  // console.log(minDate, maxDate)

  const [openSnackbar] = useSnackbar()
  const { user, setUser } = useAuth0()
  const { dateOfInterest } = React.useContext(GlobalStateContext)

  const [dd, setDD] = React.useState(
    biofix && dateOfInterest.dayOfYear >= biofix.dayOfYear
      ? biofix.date
      : dateOfInterest.date,
  )
  // console.log(dd)
  function isDayMissing(date) {
    if (datesWithNoData) {
      const dayOfYear = getDayOfYear(date)
      const isDayMissing = datesWithNoData.includes(dayOfYear)
      if (isDayMissing) return false
    }
    return true
  }

  function handleChange(d) {
    const ddd = setDate(d)
    if (user) {
      let model = user.activeTools.find((model) => model.id === modelId)
      // console.log(model)
      if (model) {
        const activeToolsUpdated = user.activeTools.filter(
          (model) => model.id !== modelId,
        )
        if (Object.keys(model[dbKey]).includes(`${dateOfInterest.year}`)) {
          model[dbKey][dateOfInterest.year][station.id] = ddd.server
        } else {
          model[dbKey][dateOfInterest.year] = {
            [station.id]: ddd.server,
          }
        }
        setUser({
          ...user,
          activeTools: [...activeToolsUpdated, model],
        })
        openSnackbar('Database has been updated!')
      }
    }
    setDD(d)
    setUserBiofix(ddd.server)
  }

  function resetBiofix() {
    if (user) {
      let model = user.activeTools.find((model) => model.id === modelId)
      if (model) {
        const activeToolsUpdated = user.activeTools.filter(
          (model) => model.id !== modelId,
        )
        if (Object.keys(model[dbKey]).includes(`${dateOfInterest.year}`)) {
          if (
            Object.keys(model[dbKey][dateOfInterest.year]).includes(station.id)
          ) {
            if (Object.keys(model[dbKey][dateOfInterest.year]).length === 1) {
              delete model[dbKey][dateOfInterest.year]
            } else {
              delete model[dbKey][dateOfInterest.year][station.id]
            }
          }
        }
        setUser({
          ...user,
          activeTools: [...activeToolsUpdated, model],
        })
        openSnackbar('Database has been updated!')
      }
    }

    if (biofix) {
      setDD(biofix.date)
    }
    setUserBiofix(null)
    setUserBiofix2(null)
  }

  const renderDayContents = (day, date) => {
    let title = ''
    if (datesWithNoData) {
      const dayOfYear = getDayOfYear(date)
      const isDayMissing = datesWithNoData.includes(dayOfYear)
      if (isDayMissing) title = 'No Data Available'
    }
    return <span title={title}>{day}</span>
  }

  React.useEffect(() => {
    if (!biofix) {
      setDD(undefined)
    } else {
      if (dateOfInterest.dayOfYear >= biofix.dayOfYear) {
        setDD(biofix.date)
      }
    }
  }, [biofix, dateOfInterest])

  return (
    <div className="">
      <label
        htmlFor={id}
        className="block mb-2 font-semibold leading-5 text-left text-gray-700 "
      >
        {label}
      </label>
      <div className="flex items-center mt-2">
        <DatePicker
          className="mr-1"
          id={id}
          selected={dd}
          onChange={(d) => handleChange(d)}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText="click to enter a date"
          fixedHeight
          filterDate={isDayMissing}
          renderDayContents={renderDayContents}
          customInput={
            <CustomInput
              placeholderText="click to enter a date"
              isDisabled={isDisabled}
            />
          }
        />
        {dd && userBiofix && (
          <>
            <button
              type="button"
              aria-label={`Clear biofix`}
              data-tip={tooltip}
              // data-event="click"
              data-for="biofix"
              onClick={resetBiofix}
              className="inline-flex items-center p-2 ml-1 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              clear
            </button>
            <ReactTooltip
              id="biofix"
              globalEventOff="click"
              // type="info"
              effect="solid"
              // aria-haspopup="true"
            />
          </>
        )}
      </div>
    </div>
  )
}
