import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      Brown, E.M. and Sutton, T.B. 1995. An empirical model for predicting the first symptoms of sooty blotch and flyspeck of apples. Plant Disease. 79:1165-1168.
      </p>
    </>
  )
}
