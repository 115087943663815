// a = Days since petal fall
// b = Rainfall since petal fall
// c = Hours of leaf wetness since petal fall

export const lookupTable = [
  {
    a_min: 0,
    a_max: 9,
    b_min: 0,
    b_max: 1.49,
    c_min: 0,
    c_max: 99,
    risk: "No risk",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 0,
    c_max: 99,
    risk: "No risk",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 2,
    b_max: 999,
    c_min: 0,
    c_max: 99,
    risk: "No risk",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 0,
    b_max: 1.49,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 2,
    b_max: 999,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 0,
    b_max: 1.49,
    c_min: 130,
    c_max: 169,
    risk: "Low",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 2,
    b_max: 999,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 0,
    b_max: 1.49,
    c_min: 170,
    c_max: 9999,
    risk: "Low",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 170,
    c_max: 9999,
    risk: "Moderate",
  },
  {
    a_min: 0,
    a_max: 9,
    b_min: 2,
    b_max: 999,
    c_min: 170,
    c_max: 9999,
    risk: "High",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 0,
    b_max: 1.49,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 2,
    b_max: 999,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 0,
    b_max: 1.49,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 2,
    b_max: 999,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 0,
    b_max: 1.49,
    c_min: 130,
    c_max: 169,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 2,
    b_max: 999,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 0,
    b_max: 1.49,
    c_min: 170,
    c_max: 9999,
    risk: "Low",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 170,
    c_max: 9999,
    risk: "Moderate",
  },
  {
    a_min: 10,
    a_max: 13,
    b_min: 2,
    b_max: 999,
    c_min: 170,
    c_max: 9999,
    risk: "High",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 0,
    b_max: 1.49,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 2,
    b_max: 999,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 0,
    b_max: 1.49,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 2,
    b_max: 999,
    c_min: 100,
    c_max: 129,
    risk: "Low",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 0,
    b_max: 1.49,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 2,
    b_max: 999,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 0,
    b_max: 1.49,
    c_min: 170,
    c_max: 9999,
    risk: "Moderate",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 170,
    c_max: 9999,
    risk: "Moderate",
  },
  {
    a_min: 14,
    a_max: 20,
    b_min: 2,
    b_max: 999,
    c_min: 170,
    c_max: 9999,
    risk: "High",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 0,
    b_max: 1.49,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 2,
    b_max: 999,
    c_min: 0,
    c_max: 99,
    risk: "Low",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 0,
    b_max: 1.49,
    c_min: 100,
    c_max: 129,
    risk: "Moderate",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 100,
    c_max: 129,
    risk: "Moderate",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 2,
    b_max: 999,
    c_min: 100,
    c_max: 129,
    risk: "Moderate",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 0,
    b_max: 1.49,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 2,
    b_max: 999,
    c_min: 130,
    c_max: 169,
    risk: "Moderate",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 0,
    b_max: 1.49,
    c_min: 170,
    c_max: 9999,
    risk: "High",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 1.5,
    b_max: 1.99,
    c_min: 170,
    c_max: 9999,
    risk: "High",
  },
  {
    a_min: 21,
    a_max: 999,
    b_min: 2,
    b_max: 999,
    c_min: 170,
    c_max: 9999,
    risk: "High",
  },
]
