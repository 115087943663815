import React from 'react'

export default function ManagementGuide() {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          Management Guide
        </h2>
      </div>

      <div className="px-6 py-6 bg-white rounded-lg shadow-lg ">
        <div className="">
          <h2 className="mb-8 text-xl font-semibold text-gray-600">
            Risk Level IPM Guidelines for Sooty Blotch and Flyspeck:
          </h2>

          <div className="grid gap-4 mb-8">
            <div className="grid grid-cols-4">
              <span className=" w-32 h-8 flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-white text-gray-800 font-semibold border">
                No Risk
              </span>
              <span className="flex items-center col-span-3 italic text-gray-600">
                No action needed.
              </span>
            </div>
            <div className="grid grid-cols-4">
              <span className=" w-32 h-8 flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm level-1 font-semibold">
                Low
              </span>
              <span className="flex items-center col-span-3 italic text-gray-600">
                If first cover application has not been made, make first cover
                fungicide application for apple scab. Otherwise, no action
                needed.
              </span>
            </div>
            <div className="grid grid-cols-4">
              <span className=" w-32 h-8 flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm level-2 font-semibold">
                Moderate
              </span>
              <span className="flex items-center col-span-3 italic text-gray-600">
                Check the 5-day forecast; a cover application should be made if
                two or more days with precipitation are predicted. See
                Fungicides below.
              </span>
            </div>
            <div className="grid grid-cols-4">
              <span className=" w-32 h-8 flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm level-4 font-semibold">
                High
              </span>
              <span className="flex items-center col-span-3 italic text-gray-600">
                A cover application for Sooty Blotch and Flyspeck should be
                made. See Fungicides below.
              </span>
            </div>
          </div>

          <h4 className="pb-8 leading-5">
            <span className="font-bold">
              Fungicides for Sooty Blotch and Flyspeck:
            </span>{' '}
            To effectively limit fruit finish blemishes from Sooty Blotch and
            Flyspeck infection consider making a cover application of one of the
            following fungicides/tank mixes:
          </h4>

          <ul className="space-y-2 text-gray-600 list-disc list-inside">
            <li>
              4 oz/100 gal Topsin +1 lb/100 gal. Captan 50W (or Captan-80 10
              oz/100 gal); or
            </li>
            <li>0.67 oz/100 gal Flint 50WG; or</li>
            <li>1.6 oz/100 gal Sovran WDG; or</li>
            <li>6.1 oz/100 gal Pristine WG; or</li>
            <li>
              1 lb/100 gal Captan 50W (or Captan-80 10 oz/100 gal) + 21 fl.
              oz./100 gal ProPhyt
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
