import GlobalStateContext from '../../context/globalStateContext'
import { format } from 'date-fns'
import React from 'react'
import { CSVLink } from 'react-csv'

const rowBg = (riskLevel) => {
  if (riskLevel === 'No risk') return ''
  if (riskLevel === 'Low') return 'level-1'
  if (riskLevel === 'Moderate') return 'level-2'
  if (riskLevel === 'High') return 'level-4'
}

export default function ResultsTable({
  station,
  modelData,
  tableData,
  csvData,
  userBiofix2,
}) {
  const { resultsTable } = modelData.elements
  const { dateOfInterest } = React.useContext(GlobalStateContext)

  let colSpan = 2
  if (userBiofix2) colSpan = 4

  if (!tableData) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {resultsTable.title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`${modelData.title} Results Table.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>

        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <span className="mb-2 mr-3 font-bold tracking-wider text-gray-700 lg:mb-0">
            Risk Level:
          </span>
          <div className="grid grid-cols-4 gap-1">
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-white text-gray-800 font-semibold font">
              No Risk
            </span>
            <span className="cell level-1">Low</span>
            <span className="cell level-2">Moderate</span>
            <span className="cell level-4">High</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-r border-gray-200 bg-secondary-800"
                    rowSpan="2"
                  >
                    Date <small>({dateOfInterest.year})</small>
                  </th>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800"
                    rowSpan="2"
                  >
                    Days since petal fall
                  </th>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800"
                    rowSpan="2"
                  >
                    Accumulated Leaf Wetness Hours
                  </th>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800"
                    colSpan={colSpan}
                  >
                    {userBiofix2
                      ? 'Rain Events and Fungicide Depletion Estimate'
                      : 'Rain Events'}
                  </th>
                </tr>

                <tr>
                  {userBiofix2 && (
                    <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                      Days since last fungicide application
                    </th>
                  )}

                  {userBiofix2 && (
                    <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                      Rain since last fungicide application
                    </th>
                  )}

                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    Daily rain amount (inches)
                  </th>

                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800">
                    <div>
                      <span className="inline-flex">Rain probability</span>
                      <span className="inline-flex">Night | Day</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((day, i) => {
                  const color = rowBg(day.riskLevel)
                  return (
                    <tr
                      key={day.ms}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td className={`td h-14`}>
                        <span
                          className={`flex flex-col items-center cell ${color}`}
                        >
                          {format(day.date, 'LLLL d')}
                          <small>{day.isForecast ? 'Forecast' : ''}</small>
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${color}`}>
                          {day.daysSincePetalFall}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${color}`}>
                          {day.accumulatedLeafWetnessHoursAboveOneMinute}
                        </span>
                      </td>

                      {userBiofix2 && (
                        <td className={`td`}>
                          <span className={'cell'}>
                            {day.daysSinceFungicide}
                          </span>
                        </td>
                      )}

                      {userBiofix2 && (
                        <td className={`td`}>
                          <span className={'cell'}>
                            {day.rainSinceLastFungisideApp}
                          </span>
                        </td>
                      )}

                      <td className={`td`}>
                        <span className={'cell'}>{day.dailyRainAmount}</span>
                      </td>

                      <td className={`td`}>
                        <span className={'grid grid-cols-2 divide-x'}>
                          <span>
                            {day.pop10pm === null ? '-' : `${day.pop10pm}%`}
                          </span>{' '}
                          <span>
                            {day.pop10am === null ? '-' : `${day.pop10am}%`}
                          </span>
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
